export const SPECIAL_ORGANIZATION_TYPES = {
  public_school_districts: ['public_school_districts'],
  military: ['medium_or_large_businesses', 'small_businesses', 'state_government_entities', 'federal_government_entities'],
} as Record<string, string[]>

export const getOrganizationTypesGroup = (organizationType: string) => {
  return Object.keys(SPECIAL_ORGANIZATION_TYPES).find(
    (key) => SPECIAL_ORGANIZATION_TYPES[key].includes(organizationType)
  );
}

export const isPublicSchoolDistrict = (organizationType: string) => {
  return SPECIAL_ORGANIZATION_TYPES.public_school_districts.includes(organizationType);
}

export const isMilitaryRelated = (organizationType: string) => {
  return SPECIAL_ORGANIZATION_TYPES.military.includes(organizationType);
}