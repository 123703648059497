// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import {
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { useQuery } from 'react-query';
import { BrowserRouter, useMatch } from 'react-router-dom';
import isEmpty from 'lodash.isempty';
import get from 'lodash.get';

import zappy from '@zappy-ride/library.react.components';

import { useCalculations } from '@zappy-ride/library.calculations';
import { CalculationsProvider } from '@zappy-ride/library.calculations';

import * as config from './config';

import {
  fetchClientConfig,
  handleFeatureFlags,
  unbrandedLocalDesings,
} from './api/client';
import { getClientApiConfigUrl } from './utils/functionHelpers';
import {
  InterceptorContext,
  InterceptorProvider,
} from './contexts/Interceptor';
import { AppStateContext, AppStateProvider } from './contexts/AppState';

import actionsHandlers, {
  LOCAL_STORAGE_LOAD,
  SET_DEFAULT_ASSUMPTIONS_DATA,
  SET_DEFAULT_INPUT_OUTPUT,
  NAVIGATE_TO,
  UPDATE_CALCULATIONS_INPUTS,
  SHARED_PROJECT_READ_QUERY,
  API_REQUEST_SET_PROJECT,
  FORM_SET_KWH_PER_DAY_NEEEDED,
} from './contexts/Interceptor/actions';

import {
  stateAttrs,
  status as statusConst,
} from './contexts/AppState/contants';
import { AnalyticsProvider } from './contexts/Analytics/AnalyticsTracker';
import merge from 'lodash.merge';
import {
  postcodeValidation,
  preparePowerSupplierValues,
} from './utils/validations';
import { useDevice } from './hooks/useDevice';
import { useUrl } from './hooks/useUrl';

import appDefaultLng from './data/fallback-lng.json';
import { useFormContext } from 'react-hook-form';
import { getLocalLang, getSessionId, loadAndStoreCurrencyConversion } from './utils/localStorageUtils';
import { ThemeProvider } from '@mui/material/styles';
import {
  AbilityProvider,
  fallbackTranslations as foundationFallbackTranslations,
  incentivesTranslations,
} from '@zappy-ride/ev-fleets';
import foundationLocales from '@zappy-ride/ev-fleets/locales/en/translation.legacy.json';
import { createAndMapTheme } from './utils/zappyToMuiThemeMapping';
import { ELECTRIC_EFFICIENCY_UNIT_KEY_NAME } from './presenters/vehicles';
import { useLangChanger } from './hooks/useLangChanger';

const { PRODUCT_NAME, DYNAMIC_CALCULATION_MODULE, DEFAULT_CONFIG_API_URL } =
  config;

const fallbackTranslations = {
  translation: {
    ...appDefaultLng.translations,
    ...foundationLocales,
    ...foundationFallbackTranslations,
    ...incentivesTranslations,
  },
  formatting: appDefaultLng.formatting,
};

zappy.initI18n(
  [],
  {
    lng: 'eng',
    fallbackLng: 'eng',
    debug: false,
  },
  () =>
    Promise.resolve({
      eng: fallbackTranslations,
    })
);

export const App = () => {
  const [clientConfigUrl] = useState(getClientApiConfigUrl(window, config));

  //TODO: Transform this into a abstraction under zappy library
  const { isError: customClientFetchHasError, ...customClientConfig } =
    useQuery(
      ['clientConfig', clientConfigUrl],
      () => fetchClientConfig(clientConfigUrl),
      {
        refetchOnWindowFocus: false,
        retry: 1,
        retryDelay: 1000,
      }
    );

  const { isIdle, ...defaultConfig } = useQuery(
    ['clientConfig', DEFAULT_CONFIG_API_URL],
    async () => {
      try {
        if (DEFAULT_CLIENT_NAME === 'unbranded')
          return handleFeatureFlags(unbrandedLocalDesings);
        return await fetchClientConfig(DEFAULT_CONFIG_API_URL);
      } catch {
        return handleFeatureFlags(unbrandedLocalDesings);
      }
    },
    {
      refetchOnWindowFocus: false,
      enabled: customClientFetchHasError,
      retry: false,
    }
  );

  const {
    isError,
    isLoading,
    error,
    data: clientConfig,
  } = useMemo(
    () => (isIdle ? customClientConfig : defaultConfig),
    [customClientConfig, defaultConfig]
  );

  const {
    loadPath,
    supportedLngs = [],
    defaultLng,
    fallbackLng,
    loadPaths,
    loadPathsForLogo,
  } = get(clientConfig, 'configs.translations', {});

  const selectedLanguage = getLocalLang() || get(clientConfig, 'languageSelected', defaultLng);

  //this code enhance the supportedLngs to include the language without region
  //e.g if the supportedLngs is ['en-US', 'es-ES'] we need to include ['en', 'es'] as well
  //if already contains, we don't need to include it
  const enhancedSupportedLngs = useMemo(() => {
    const lngsWithoutRegion = supportedLngs.map((lng) => lng.split('-')[0]);

    //concat and remove duplicates
    return supportedLngs
      .concat(lngsWithoutRegion)
      .concat(fallbackLng)
      .concat(loadPaths ? Object.keys(loadPaths) : [])
      .filter((v, i, a) => a.indexOf(v) === i);
  }, [supportedLngs, loadPaths]);

  const getTranslationResources = () =>
    Promise.resolve({
      [fallbackLng]: fallbackTranslations,
    });

  const preloadConfig = useMemo(
    () => ({ ...config, api: clientConfig?.api }),
    [clientConfig]
  );
  const muiTheme = useMemo(
    () => createAndMapTheme(clientConfig?.theme),
    [clientConfig]
  );

  const loadReady = clientConfig && !isLoading && !isError;

  useEffect(() => {
    if (loadReady) {
      window.globalLoading(false);
      localStorage.setItem(
        ELECTRIC_EFFICIENCY_UNIT_KEY_NAME,
        clientConfig.configs.electricEfficiencyUnit
      );
    }
  }, [isLoading, isError, clientConfig, loadReady]);

  if (isLoading) {
    return false;
  }

  if (isError) {
    return <div>{error}</div>;
  }

  const dynamicCalculationModule = get(
    clientConfig,
    'configs.dynamicCalculationModule',
    DYNAMIC_CALCULATION_MODULE
  );

  const analyticsConfig = get(clientConfig, 'analytics');

  const _formDefaultValues = get(clientConfig, 'form.defaultValues');
  const configs = get(clientConfig, 'configs');
  const defaultPowerSuppliers = get(configs, 'defaultValues.powerSuppliers');
  const formDefaultValues = {
    sessionID: getSessionId(),
    ..._formDefaultValues,
    ...preparePowerSupplierValues(defaultPowerSuppliers),
    languageSelected: selectedLanguage, //make sure the form default values language is the language saved on the local storage
  };
  const formProps = merge({}, clientConfig.form, {
    defaultValues: formDefaultValues,
  });

  const abilities = clientConfig?.configs?.abilities ?? {};

  return (
    <zappy.ThemeProvider theme={clientConfig.theme}>
      <ThemeProvider theme={muiTheme}>
        <AbilityProvider abilities={abilities}>
          <zappy.DesignsContextProvider
            product={PRODUCT_NAME}
            designs={clientConfig.designs as Designs}
          >
            <zappy.TranslationsProvider getResources={getTranslationResources}>
              <zappy.Form {...formProps}>
                <zappy.PreloadsProvider
                  config={preloadConfig}
                  preloads={clientConfig.preloads}
                >
                  <BrowserRouter>
                    <AnalyticsProvider
                      config={analyticsConfig}
                      userId={formProps.defaultValues.sessionID}
                    >
                      <CalculationsProvider
                        dynamicModulePath={dynamicCalculationModule}
                      >
                        <AppStateProvider>
                          <InterceptorProvider
                            {...clientConfig.interceptor}
                            actionsHandlers={actionsHandlers}
                            formDefaultValues={formDefaultValues}
                          >
                            <AppStateInterceptorRoutes
                              configurations={clientConfig}
                              loadReady={loadReady}
                              langData={{
                                defaultLng,
                                fallbackLng,
                                enhancedSupportedLngs,
                                loadPath,
                                loadPaths,
                                loadPathsForLogo,
                                getTranslationResources,
                              }}
                            />
                          </InterceptorProvider>
                        </AppStateProvider>
                      </CalculationsProvider>
                    </AnalyticsProvider>
                  </BrowserRouter>
                </zappy.PreloadsProvider>
              </zappy.Form>
            </zappy.TranslationsProvider>
          </zappy.DesignsContextProvider>
        </AbilityProvider>
      </ThemeProvider>
    </zappy.ThemeProvider>
  );
};

const AppStateInterceptorRoutes = ({ configurations, langData, loadReady }) => {
  const { configs, constants } = configurations;
  const { rateEngineParams, country } = configs;
  rateEngineParams.headers = {
    'Content-Type': 'application/json',
    Authorization: get(configurations, 'api.Authorization'),
  };

  const { setData } = useCalculations();
  const { setValue, getValues } = useFormContext();
  const currencyConversion = getValues(
    'assumptionsData.currencyConversion'
  )
  useEffect(() => {
    loadAndStoreCurrencyConversion(currencyConversion);
  }, [currencyConversion]);

  useDevice();
  useUrl();

  const callAction = useContext(InterceptorContext);
  const { appState, changeAppStatus, changeResourceState } =
    useContext(AppStateContext);
  const {
    preloads: {
      chargers,
      location,
      generic_vehicles,
      fossil_vehicles,
      power_suppliers,
      power_suppliers_zipcodes,
    } = {},
  } = useContext(zappy.PreloadsContext);

  const onboarding = useMatch('/onboarding/*');
  const output = useMatch('/output/*');
  const print = useMatch('/print/*');

  const { zipcode } = zappy.useWatch({ name: 'zipcode' });
  const { organizationType } = zappy.useWatch({ name: 'organizationType' });
  const {language} = useLangChanger({...langData })

  const {
    isLoadedFromSharedLink,
    isStoredDataSetupDone,
    isLoadedFromLocalStorage,
    isCalculationsReady,
    isAssumptionsDataReady,
    isOutputPreloadsReady,
    isInputOutputReady,
    isReady,
    isLocationReady,
    isFiltersChanged,
    isPowerSuppliersReady,
  } = appState;

  useEffect(() => {
    if(!loadReady) return;

    if (onboarding) {
      setValue('filters.organizationType', organizationType);
    }
    if(langData.loadPaths) {
      setValue('assumptionsData.langEnabled', true)
    }
    if(langData.loadPathsForLogo) {
      setValue('assumptionsData.logoLanguage', langData.loadPathsForLogo[language])
    }
  }, [organizationType, langData.loadPaths, langData.loadPathsForLogo, language, loadReady]);

  useEffect(() => {
    console.log('>>>>> zipcode changed', zipcode);
  }, [zipcode]);

  useLayoutEffect(() => {
    callAction({ type: SHARED_PROJECT_READ_QUERY });
  }, []);
  /**
   * Try to load data from localStorage into form state at first render
   */
  useEffect(() => {
    if (!isStoredDataSetupDone && isLoadedFromSharedLink !== null) {
      console.log('>>>> LOCAL_STORAGE_LOAD');
      callAction({ type: LOCAL_STORAGE_LOAD });
    }
  }, [isStoredDataSetupDone, isLoadedFromSharedLink]);

  /**
   * If data loaded from localStorage set ready the resources eligible for that
   */
  useLayoutEffect(() => {
    if (!isLoadedFromLocalStorage || !isStoredDataSetupDone) return;

    console.log('>>>> UPDATE_CALCULATIONS_INPUTS');
    callAction({ type: UPDATE_CALCULATIONS_INPUTS });

    changeResourceState(stateAttrs.IS_ASSUMPTIONS_DATA_READY, true);
  }, [isLoadedFromLocalStorage, isStoredDataSetupDone]);

  /**
   * Once localStorage process is done, check if data is loaded from it or not
   * if so redirect to output page
   */
  useLayoutEffect(() => {
    if (
      isReady ||
      !isStoredDataSetupDone ||
      output ||
      !isLoadedFromLocalStorage ||
      print
    )
      return;

    console.log('>>>> NAVIGATE_TO');
    callAction({ type: NAVIGATE_TO, payload: { to: '/output' } });
  }, [output, isStoredDataSetupDone, isLoadedFromLocalStorage]);

  /**
   * Form input and output details need to be loaded based on the handle passed
   * into default values before moving forward to onboarding or output, but
   * this only need to run if data is not loaded from localStorage
   */
  useEffect(() => {
    if (
      !isStoredDataSetupDone ||
      isInputOutputReady ||
      isEmpty(generic_vehicles) ||
      isEmpty(fossil_vehicles)
    )
      return;

    console.log('>>>> SET_DEFAULT_INPUT_OUTPUT');
    const { ice: _ice, ev } = get(configs, 'defaultValues.onboarding', {});
    const ice = _ice || {
      input: ev.output,
      output: ev.input,
    }
    callAction({
      type: SET_DEFAULT_INPUT_OUTPUT,
      payload: { ice, ev },
    });
  }, [
    generic_vehicles,
    fossil_vehicles,
    isStoredDataSetupDone,
    isInputOutputReady,
  ]);

  /**
   * Set when all mandatory prealods needed to output are loaded
   */
  useLayoutEffect(() => {
    if (
      isOutputPreloadsReady ||
      isEmpty(chargers) ||
      !isLocationReady ||
      isEmpty(generic_vehicles)
    )
      return;

    console.log('>>>> IS_OUTPUT_PREALODS_READY');
    changeResourceState(stateAttrs.IS_OUTPUT_PREALODS_READY, true);
  }, [chargers, isLocationReady, generic_vehicles, isOutputPreloadsReady]);

  /**
   * Set valid powerSuppliers postcodes to the validation
   */
  useLayoutEffect(() => {
    if (!power_suppliers_zipcodes) return;
    postcodeValidation.setZipcodes(power_suppliers_zipcodes);
  }, [power_suppliers_zipcodes]);

  /**
   * Chargers and rate engine config (rateEngineParams) need to be set to
   * calculations before start using it to generated stats data
   */
  useLayoutEffect(() => {
    if (
      isCalculationsReady ||
      isEmpty(chargers) ||
      !setData ||
      !rateEngineParams ||
      !location
    )
      return;

    const defaultGridIntensity = get(constants, 'defaultGridIntensity', 0);
    const gridIntensity = get(
      location,
      'carbon_credits[0].grid_intensity',
      defaultGridIntensity
    );

    setData({
      chargers: chargers.sort((a, b) => a.price - b.price),
      rateEngineParams,
      gridIntensity,
      country
    });

    setValue('assumptionsData.country', country);

    console.log('>>>> IS_CALCULATIONS_READY');
    changeResourceState(stateAttrs.IS_CALCULATIONS_READY, true);
  }, [chargers, setData, rateEngineParams, isCalculationsReady, location, country]);

  /**
   * Set if app is fully ready to run
   */
  useLayoutEffect(() => {
    if (isReady) return;
    if (
      isStoredDataSetupDone &&
      ((onboarding && isInputOutputReady) ||
        ((output || print) &&
          isAssumptionsDataReady &&
          isCalculationsReady &&
          (isInputOutputReady || isLoadedFromLocalStorage) &&
          isOutputPreloadsReady))
    ) {
      console.log('>>>> FULL_SETUP_DONE');
      changeAppStatus(statusConst.FULL_SETUP_DONE);

      /**
       * if input is loaded after setKwhPerDayNeeded triggered it does not run
       * again, so this force set kWhPerDayNeeded after input has value
       */
      callAction({
        type: FORM_SET_KWH_PER_DAY_NEEEDED,
        payload: {
          name: 'kWhPerDayNeeded',
        },
      });
    }

    if (isStoredDataSetupDone && onboarding && isInputOutputReady) {
      window.globalLoading(false);
    }
    if (print) {
      window.globalLoading(false);
    }
  }, [
    onboarding,
    output,
    isStoredDataSetupDone,
    isLoadedFromLocalStorage,
    isAssumptionsDataReady,
    isCalculationsReady,
    isInputOutputReady,
    isOutputPreloadsReady,
    print,
  ]);

  /**
   * At first loading save local storage is not enabled to avoid sharedProject
   * or localStorage data to be saved into localStorage unnecessarily
   */
  useLayoutEffect(() => {
    if (
      !appState.isSaveToLocalStorageEnabled &&
      ((appState.isStoredDataSetupDone &&
        !appState.isLoadedFromLocalStorage &&
        !appState.isLoadedFromSharedLink) ||
        appState.isProjectReady)
    ) {
      localStorage.setItem('version', appState.version);
      console.log('>>>> IS_SAVE_TO_LOCALSTORAGE_ENABLED');
      changeResourceState(stateAttrs.IS_SAVE_TO_LOCALSTORAGE_ENABLED, true);
    }
  }, [
    appState.isSaveToLocalStorageEnabled,
    appState.isStoredDataSetupDone,
    appState.isLoadedFromLocalStorage,
    appState.isLoadedFromSharedLink,
    appState.isProjectReady,
  ]);

  /**
   * Validate that location has data corresponding the current zipcode
   */
  useEffect(() => {
    console.log('>>>> VALIDATE LOCATION USE EFFECT', zipcode);
    if (!isStoredDataSetupDone || isEmpty(location)) return;
    const zipcodeValue = `${zipcode}`;

    if (location.postcode !== zipcodeValue && isLocationReady) {
      console.log('>>>> IS_LOCATION_READY', false, zipcode);
      changeResourceState(stateAttrs.IS_LOCATION_READY, false);
      changeResourceState(stateAttrs.IS_POWER_SUPPLIERS_READY, false);
      changeResourceState(stateAttrs.IS_ASSUMPTIONS_DATA_READY, false);
    }

    if (location.postcode === zipcodeValue && !isLocationReady) {
      console.log('>>>> IS_LOCATION_READY', true);
      changeResourceState(stateAttrs.IS_LOCATION_READY, true, zipcode);
    }
  }, [
    zipcode,
    location,
    isStoredDataSetupDone,
    isLocationReady,
    isFiltersChanged,
  ]);

  /**
   * Listen for power suppliers change
   */
  useEffect(() => {
    if (isEmpty(power_suppliers)) return;

    console.log('>>>> IS_POWER_SUPPLIERS_READY', true);
    changeResourceState(stateAttrs.IS_POWER_SUPPLIERS_READY, true);
  }, [power_suppliers]);

  useEffect(() => {
    if (
      !isPowerSuppliersReady ||
      !isLocationReady ||
      (isAssumptionsDataReady && !isFiltersChanged)
    )
      return;

    const isNotFirstTimeSetupAssumptions = isAssumptionsDataReady !== null;
    console.log(
      '>>>> SET_DEFAULT_ASSUMPTIONS_DATA',
      isNotFirstTimeSetupAssumptions && ' (UPDATE)'
    );
    callAction({
      type: SET_DEFAULT_ASSUMPTIONS_DATA,
      payload: {
        update: isNotFirstTimeSetupAssumptions,
      },
    });

    if (isFiltersChanged) {
      console.log('>>>> API_REQUEST_SET_PROJECT (FIRTER CHANGED)');
      callAction({ type: API_REQUEST_SET_PROJECT });
      changeResourceState(stateAttrs.IS_FILTERS_CHANGED, false);
    }
  }, [
    isAssumptionsDataReady,
    isLocationReady,
    isFiltersChanged,
    isPowerSuppliersReady,
  ]);

  return <zappy.LayoutWithRoutes design="app" key={language}/>;
};
